<template>
  <div class="grid-standard gap-y-16" data-test="product-card-grid">
    <div
      v-for="(product, index) in products"
      :key="product.title"
      :ref="setupViewportTrackingProduct(product, tracking)"
      class="md:col-span-2 lg:col-span-3"
    >
      <ProductCard
        :description="product.description"
        :image-src="product.image"
        :listing-id="product.listingId"
        :price="product.price"
        :review-rating="product.reviewRating"
        :title="product.title"
        :url="`/${product.productPageLink.params.locale}/p/${product.productPageLink.params.slugV2}/${product.productPageLink.params.uuid}`"
        v-on="addToCartListener ? { addToCart: handleAddToCart } : {}"
        @click="
          trackProductClick(product, { ...tracking, position: index + 1 })
        "
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { getCurrentInstance } from 'vue'

import {
  type ExtraTrackingData,
  useClickTracking,
  useImpressionTracking,
} from '../../composables/useProductTracking'
import type { Product, Products } from '../../models/product'
import ProductCard from '../ProductCard/ProductCard.vue'

defineProps<{
  products: Products
  tracking: ExtraTrackingData
}>()

const emit = defineEmits<{
  (e: 'add-to-cart', data: { status: string; product: Product }): void
}>()

const addToCartListener = getCurrentInstance()?.vnode.props?.onAddToCart

const { setupViewportTrackingProduct } = useImpressionTracking()
const { trackProductClick } = useClickTracking()

function handleAddToCart(product: Product) {
  return ({ status }: { status: string }) => {
    emit('add-to-cart', { status, product })
  }
}
</script>
