import { useAsyncData } from '#imports'
import { hash } from 'ohash'

import type { ProductsBlockApiProps } from '@backmarket/http-api/src/api-specs-content/models/products-block'
import type { RefinedProductsApiProps } from '@backmarket/http-api/src/api-specs-content/models/refined-products'
import algoliasearch from 'algoliasearch'

import { mapAlgoliaResultsToProducts } from './algolia/algoliaAdapter'
import { type AlgoliaProductHit } from './algolia/types'
import {
  type InitializableAlgoliaClient,
  useAlgoliaWithDeps,
} from './algolia/useAlgolia'

type Params =
  | Pick<ProductsBlockApiProps, 'productQueries'>
  | Pick<RefinedProductsApiProps, 'productQuery'>

function buildAlgoliaParams(props: Params) {
  const productQueries =
    'productQueries' in props ? props.productQueries : [props.productQuery]

  const { apiKey } = productQueries[0]

  const queries = productQueries.map(({ indexName, params }) => ({
    indexName,
    params,
  }))

  return { apiKey, queries }
}

export const useSearchProductsWithDeps =
  (initializableAlgoliaClient: InitializableAlgoliaClient<AlgoliaProductHit>) =>
  (props: Params) => {
    const uniqueKey = hash(props)
    const { search } = useAlgoliaWithDeps({ initializableAlgoliaClient })

    return useAsyncData(uniqueKey, async () => {
      const algoliaParams = buildAlgoliaParams(props)

      const algoliaResponse = await search(algoliaParams)

      return mapAlgoliaResultsToProducts(algoliaResponse)
    })
  }

export function useSearchProducts(params: Params) {
  return useSearchProductsWithDeps(
    algoliasearch as InitializableAlgoliaClient<AlgoliaProductHit>,
  )(params)
}
